.top-home {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/front-page.webp');
    padding-bottom: 50px;
    height: 520px !important;
    background-position: center top !important;
    max-height: 85vh;

}

.second-home {
    background-image: url('/img/front-page2.webp');
    height: 520px !important;
    max-height: 100vw;
    background-position: center !important;
}
// for now!

.top-about {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/about_5.webp');
    padding-bottom: 50px;
    height: 650px;
}

.second-about {
    background-image: url('/img/about_2.webp');
    height: 650px;
}
.aboutimg1 {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.48)),
    url('/img/about_7.webp');
    background-size: cover;
    background-position: top;
    height: 500px;
    width: 100%;
}
.about4 {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.58)),
    url('/img/about_4.webp');
    background-size: cover;
    background-position: center;
    height: 500px;
    width:100%;
}

.aboutlead {
    font-size: 1.1rem;

    font-weight: 150;
    color: $lead-text;
}

.top-team {
    background-image: url('/img/about_1.webp');
    padding-bottom: 50px;
    height: 650px;
}

.teambox
{

    padding-top: 0.75rem;
    padding-bottom: 1rem;
    border-radius: 5px;
}

.team
{
    color: #fff !important;
    background-size: cover;
    background-position: center;
    height: 350px;
    width: 250px;
    padding: 1rem;
    padding-top: 1.25rem;
    line-height: normal !important;
    display: table-cell;
    vertical-align: bottom;
    -webkit-transition: filter 0.25s; /* For Safari 3.1 to 6.0 */
    transition: filter 0.25s;

    h4 {
        margin-bottom: 0.75rem !important;
        font-weight: bold;
    }
    .divider {
        margin-top: 1.5rem;
        margin-bottom: 0rem !important;
    }
    p {
        margin-bottom: 0rem !important;
    }

}
div.team:hover
    {
        -webkit-filter: brightness(75%); /* Safari 6.0 - 9.0 */
        filter: brightness(75%);
    }


.teamcap
{
    width: 250px !important;
    height: 135px;
    padding: 1rem;
    padding-top: 1.25rem;
    line-height: normal !important;
    color: white;
    h4 {
        margin-bottom: 0.75rem !important;
        font-weight: bold;
    }
    .divider {
        margin-top: 1.5rem;
        margin-bottom: 0rem !important;
    }
}
.profile
{
    width: 80%;
    max-width: 1100px !important;
    img {
        height: 400px;
        max-width: 100%;

    }

    div {border-bottom: 0px !important;
        border-top: 0px !important;}

.container {
background-color: rgba(0, 0, 0, 0.0);
}

    .divider {
        margin-bottom: 0rem !important;
    }
}

.teamfolio
{
    width: 80%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.clickyclicky {
    cursor: pointer;
}

.top-wedo {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/wedo.webp');
    background-position: center 55% !important;
    padding-bottom: 50px;
    height: 650px;
}

.wedoimg {
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 500px;
}

.wedorms {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.30)),
    url('/img/wedorms.webp');
}

.wedome {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.30)),
    url('/img/wedome.webp');
}

.wedocap {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.30)),
    url('/img/wedocap.webp');
}

.wedodat {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.30)),
    url('/img/wedodata.webp');
}

.wedotext {
    padding-left: 3rem;
    padding-right: 3rem !important;
}

.wedotextright {
    padding-left: 5rem;
    padding-right: 0rem !important;
}

.wedotextleft {
    padding-right: 4rem;
    padding-left: 0rem !important;
}

.top-ethics {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/ethics.webp');
    padding-bottom: 50px;
    height: 650px;
    background-position: center 40% !important
    ;
}

.ethicsimg1 {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.48)),
    url('/img/ethics2.webp');
    background-size: cover;
    background-position: center;
    min-height: 600px;
    width: 100%;
}

.ethicsnfp {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.30)),
    url('/img/tef.webp');
    min-height: 400px !important;
}

// .top-resource {
//     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/resource.webp');
//     padding-bottom: 50px;
//     height: 95vh !important;
//     background-position: center;
// }
.top-resource {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(5, 0, 10, 0.96)), url("/img/resource.webp");
    padding-bottom: 50px;
    height: auto !important;
//    min-height: 75vh;
    background-position: center;



}

.top-resource h1 {
    font-size: calc(2.5rem + 2.5vw);
}
.top-resource h3 {
    font-size: calc(1.1rem + 0.8vw);
    font-weight: 100;
    line-height: 1.5;
    margin-top: 3rem;
    strong {
        font-weight: 600;
    }
}
.top-blog {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/blog.webp');
    padding-bottom: 50px;
    height: 650px;
    background-position: center 35% !important;
}


// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: .9rem;
$line-height-base: 1.6;


$lead-text: #3b3b3b;
$default-text: #0e0e0e;
$stats4sd: #d32329;
$transparent: rgba(0, 0, 0, 0);

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #eee !default;
$gray-200: #ddd !default;
$gray-300: #ccc !default;
$gray-400: #bbb !default;
$gray-500: #aaa !default;
$gray-600: #888 !default;
$gray-700: #666 !default;
$gray-800: #444 !default;
$gray-900: #222 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$shadow: rgba(0, 0, 0, .04);

// NOTE: Laravel Mix defines "~" as a shortcut to the node_modules folder. However, I can't get my SCSS linter to recognise this, so it keeps giving me an error saying it cannot find the import files.
// Using the ~ lines work fine in practice, it's just annoying with my linter turned on. One day I'll fix my linter and we can all use the best-practice for Laravel Mix...;
// @import '~bootstrap/scss/bootstrap';
// @import '~font-awesome/scss/fontawesome';

@import "../../vendor/spatie/laravel-medialibrary-pro/resources/js/media-library-pro-styles";

// Variables
@import 'variables';
@import 'test';

// Dependencies
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

@import '../../node_modules/bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/simple-line-icons/scss/simple-line-icons';

@import 'pages/home';




/*
|--------------------------------------------------------------------------
| Core Stuff - basic changes;
|--------------------------------------------------------------------------
*/

.text-small {
    font-size: 0.8em;
}

body {
    background-color: $white;
    font-family: $font-family-sans-serif;
    color: $default-text;
}

h3 {

  font-size: 1.35rem;
}
h3.strap {
    font-size: 1.8rem;
    font-weight: 150;
    color: $lead-text;
}
ul {
    margin-inline-start: -1.5em;
}

h2 {

    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.575rem;
    // line-height: 0.7em;
}

a {
    color: $stats4sd;
}

a:hover {
    color: $stats4sd;
}

p {
    margin-bottom: 0rem !important;
}



/*
|--------------------------------------------------------------------------
| Layout
|--------------------------------------------------------------------------
*/
.whole-page {
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
}

.container {
    background-color: $white;

    &.see-through {
        background-color: $transparent;
    }
}

.navbar {
    min-height: 82px;
    padding: .5rem 0;
    background-color: $white;

    .navbar-brand {
        display: inline-block;
        width: 140px;
        text-align: center;
    }

    .nav-link {
        color: $lead-text;
        text-transform: uppercase;

        &.active {
            color: $stats4sd !important;
        }
    }

    .navbar-nav {
        text-align: right;
    }
}

.nav-pills {
    .nav-link.active {
        background-color: $stats4sd !important;
    }
}

.foot {
    background-color: #e9ecef;
    font-size: 0.8rem;

    a:link  {
        color: $default-text;
        text-decoration: underline;

    }
    a:hover {
        text-decoration: none;
    }

}

.fimg {
    display: inline-block;
    max-width: 30%;
    max-height: 3rem;
    height: auto;
    padding: 1.5%;
}

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/

.btn-stats {
    background-color: $stats4sd;
    color: $white !important;

}
a.btn-stats:hover{
    color: rgba(255, 255, 255, 0.7) !important;
    text-decoration: none !important;

}
.btn-wide {
    min-width: 250px;
    text-align: center;
}

.divider {
    height: 0.4rem;
    width: 60px;
    background-color: $stats4sd;
    margin-bottom: 1.2rem;
    position:relative;
  }

// full-width images
// currently using background-images (see pages/home for example) but could change to using <img> tags maybe possibly...
.full-img {
    max-width: 100%;
    height: 45vh;

    margin-bottom: 1.5rem;
    background-repeat: no-repeat;
    background-position: center 18%;
    background-size: cover;
    color: $white !important;


    h1 {
        font-weight: bold;
    }
}

.full-banner {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    min-height: 200px;

    color: $white !important;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    // background-position: center;

    margin-bottom: 50px;
    h1 {
        font-weight: bold;
    }

}


.staging-link a {
    color: $white !important;
}


.badge-stats {
background-color: $stats4sd;
color: #fff;

}

.alert-stats {

    border: none;
    border-radius: 0rem;

}
button.alert-stats:hover {

        background-color:#d7e3ee !important;

}

/*
|--------------------------------------------------------------------------
| Resources Page
|--------------------------------------------------------------------------
*/

.service-icon {
  background-color: $white;
  color: $primary;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.resource-results-item {
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
    margin-left: 0rem !important;
    margin-bottom: 0rem !important;
    padding-bottom: 0rem !important;
    padding-top: 0.35rem !important;
    color: $default-text !important;
    //max-height: 250px;
}

.card-hover {
    border-right: 3px solid #fff !important;
    border-bottom: 3px solid #fff !important;
    padding: 1rem;

    &:hover {
      border-right: 3px $gray-100 solid !important;
      border-bottom: 3px $gray-100 solid !important;
      //background-color: rgba(0, 0, 0, 0.02);

        h3 {
//            text-decoration: underline !important;
            text-decoration-color: $stats4sd !important;
        }
        img {
            opacity: 0.8;
        }
    }
}

a.card-link {
    color: $stats4sd;
    font-weight: bold;
}

a.card-link:hover {
    color: #0e0e0e !important;
    text-decoration: none;
}

.fill {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 200px;

    img {
        flex-shrink: 0;
        min-width: 100%;
        //min-height: 100%;


    }
}
.fill img:hover
{
    opacity: 0.8;
}


.element-card {
    a {
        color: $black;
    }
}

p.card-body {

        padding-left: 0rem;
    }



.res-el:hover {

    .res-und {
        text-decoration: underline;
    }

}
.btn-reslist {
    text-decoration: none !important;
    position: static;
}



// *****************************************************
// Video embed
// *****************************************************
.videowrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

// *****************************************************
// Google Maps Embed
// *****************************************************

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


// *****************************************************
// BLOG POSTS
// *****************************************************

.post-meta {
    color: $gray-700;
    margin-bottom: 1em;
}


.featured-article {
    background-size: cover;
    background-position: center;
}

.sidebar-box {
    .list-group {
        margin-inline-start: 0;
     }
}

.tags {

    list-style-type: none;

    li {

        padding: 0;
        margin: 0 4px 4px 0;
        float: left;
        display: inline-block;

        span {
            float: left;
            display: block;
            border-radius: 4px;

            padding: 2px 6px;
            color: #262727;
            background: #daf0f0;
        }
    }
}
.list-group-item.active {
    background-color: #fff !important;
    border: none;
    color: $default-text !important;
    font-weight: bold;

    }
    .list-group-item:hover {
        background-color: #f9f9f9 !important;
        color: $default-text !important;

        }

.card {
    border-style: none;
    border-radius: 0px !important;
}
.card-header {
    border-style: none;
    background-color: #fff;
}

.card-title {
    font-weight: bold;
    color: $gray-800;
    p {
        font-weight: normal !important;
        padding: 0rem !important;
        color: $default-text !important;
    }
}
//.card-body {
//   padding-top: 0.75rem !important;
//}
.blogdivider {
    margin-left: 1.4rem;
    margin-top: 1.2rem;
    margin-bottom: 0rem !important;
}
.badge {
        background-color: #eff1f7 !important;
        color: #252627 !important;
        font-weight: 550 !important;
        margin-right: 0.2rem;
    }

.alert {

    color: $gray-800 !important;
    background-color: #f1f5f8 !important;
    border-radius: 0.8rem;
    border: none !important;
    margin-bottom: 1rem !important;
}

.res h1 {
    font-size: 1.2 rem;

}

.MyCustomPoweredBy {
    display: inline-block;
}

.cover-img {
    max-width: 50%;

    img {
        min-width: 20rem;
    }
}

@media (max-width: 990px){
    .cover-img {
        max-width: 100%;
    }
}

.comment {
    width: 100%;
}

// Flex Form/Column layouts
.flex-outer li,
.flex-inner {
  display: flex;
  align-items: center;
}

.flex-outer > li > label,
.flex-outer li p {
  flex: 1 0 120px;
  max-width: 220px;
  text-align: right;
  padding-right: 15px;
}

.flex-outer > li > label + *,
.flex-inner {
  flex: 1 0 220px;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  min-height: 0px;
  padding-bottom: 0px;
}



.filterbox {
    margin-bottom: 3rem;

    .navbar {
        border-bottom-color: transparent;
        background-color: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .container {
        background-color: transparent;
        border-bottom-color: transparent;
    }

    .nav-item {
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        border-bottom-color: transparent;
        padding-left: 0.8em;
        padding-right: 0.8em;
        margin-bottom: -2px;

    }
    .nav-item.not-collapsed {
        .nav-link {
        color: $stats4sd !important;
        }
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 2px solid #ffffff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #ffffff;
    }

    .nav-link {
        color: rgba(0, 0, 0, 0.7);
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .nav-link:hover {
        color: $stats4sd !important;
    }
    .collapse {
        // border-top: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 8px 7px 0 rgba(0, 0, 0, 0.05), 0 10px 15px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: -1px;
        background-color: #ffffff;

    }


}

@media only screen and (max-width: 600px) {
    .secondary-filter {
        width: 100%;

    }
    .multi-filter {
        flex-wrap: wrap;
    }

}
ul.frame {
    padding-left: 0px;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}


#resources-app .ais-SearchBox-form {
    margin-bottom: 0;
}

@media only screen and (max-width: 1000px) {
    .resource-nav {
        flex-wrap: wrap;
    }
}

.alert.mb-0 {
    margin-bottom: 0 !important;
}



